
.breadcrumbs--wrapper {
  padding: 22px 3px;
  font-size: 16px;
  border-radius: 20px;
  position: relative;
  top: -17px;
  background: whitesmoke;


  .breadcrumbs--text {

  }


  .container{
    a{
      &:last-of-type{
        font-weight: bold;
      }
    }
  }

  a {
    position: relative;




    &:not(:last-child) {
      margin-right: 26px;
      &:before {
        content: '';
        font-size: 16px;
        display: block;
        position: absolute;
        width: 14px;
        height: 2px;
        background-color: #3e3e3e;
        right: -23px;
        top: 50%;
        transform: translate(0px, -50%);
      }
    }
  }
}
