.consultation-box-overlay{
  &.is-visible{
    display: block;
  }
  display: none;
  position: fixed;
  top:0px;
  bottom:0px;
  left:0px;
  right:0px;
  z-index: 4;
  background-color: rgba(39,39,39,0.61);
}

.consultation-box{
  &.is-visible{
    opacity: 1;
    transform: translateY(-50%);
    pointer-events: all;
  }

  pointer-events: none;
  opacity: 0;
  position: fixed;
  max-width:535px;
  top:50%;
  transform: translateY(-60%);
  border-radius: 15px;
  padding: 51px 30px 34px;
  left: 0px;
  right: 0;
  margin: 0 auto;
  background-color: #FFF;
  text-align: center;
  z-index: 5;
  transition: all 0.3s linear;

  h2{
    font-size:36px;
    font-weight: bold;
    margin:0px 0px 3px;
    text-transform: uppercase;
  }

  p{
    font-size:18px;
    font-weight: 400;
    color:#212121;
    margin:0px 0px 19px;
  }

  small{
    margin-top: 13px;
    display: block;
    font-size: 14px;
  }

  img{
    position: absolute;
    right: 9px;
    top:12px;
    height: 52px;
    width: 52px;
    cursor: pointer;
  }
}