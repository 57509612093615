//@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,700;0,900;1,400,300&display=swap');
//@import url('https://fonts.googleapis.com/css2?family=Caveat:wght@700&display=swap');
//@import "~bootstrap/scss/bootstrap";

@import 'variables';
@import 'typography.scss';
@import 'partials/main.scss';
@import 'partials/header.scss';
@import 'partials/search-panel.scss';
@import 'partials/footer.scss';
@import 'partials/grid.scss';
@import 'partials/breadcrumbs.scss';
@import 'partials/car-grid.scss';
@import 'partials/buttons.scss';
@import 'partials/consultation-box.scss';
@import 'partials/form.scss';

@import 'components/car-brands-slider';
@import 'components/cta.scss';
@import 'components/our-offer.scss';
@import 'components/our-services.scss';
@import 'components/faq.scss';
@import 'components/homepage-hero.scss';

@import 'templates/template-page.scss';
@import 'templates/archive-brand.scss';
@import 'templates/archive-model.scss';
@import 'templates/archive-search.scss';
@import 'templates/single-car.scss';
@import 'templates/template-404.scss';
@import 'templates/template-authenticator.scss';
@import 'templates/template-contact.scss';
@import 'templates/template-sale.scss';
@import 'templates/template-how-it-works.scss';



@import 'components/filter-form.scss';
@import 'components/select2.css';

@import 'vendor/flickity.min.css';
@import 'vendor/bootstrap-grid.min.css';
@import 'vendor/aos.css';
@import 'vendor/lightbox.css';
@import 'vendor/cookieconsentpro-public';


.text-thin{
  font-weight: 300;
}

.alert{
  padding: 15px 0px;
  text-align: center;
  color: #FFF;
  margin-bottom: 40px;
  font-size: 16px;

  &.alert-success{
    background: $successColor;
  }
  &.alert-error{
    background: red;
  }
}

.archive-title{
  h1{
    @media(max-width:500px){
      font-size:26px;
    }
    @media(max-width: 991px) and(min-width: 768px) {
      font-size: 32px;
    }
  }
}