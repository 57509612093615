section.page-sale{
  padding: 0px 0px 80px 0px;
  .archive-title--wrapper{
    padding-top: 20px;
    .row{
      display: flex;
      align-items: center;
      justify-content: space-between;

    }
    .archive-title,.archive-count{
      flex:1;
    }

    .archive-count{
      text-align: right;
      p{
        font-size: 1.1em;
        text-transform: uppercase;
        letter-spacing: 1.2px;
        color: #707070;
        font-weight: bold;
      }
    }

    .archive-title{
      display: flex;
      flex-direction: revert;
      align-items: center;


      h1{
        font-size: 30px;
        margin: 0px;
        color: #707070;
        font-weight: 600;
        letter-spacing: 1.4px;
      }
    }

  }

  .archive-content--wrapper{
    padding: 80px 0px 0px;
  }
}
