section.faq{
  margin-bottom:100px;
  h2{
    font-size:48px;
    font-weight: bold;
    margin:0px 0px 13px;
    @media(max-width: 500px){
      font-size:36px;
    }
    @media(max-width:767px)and(min-width:501px){
      font-size:36px;
    }
    @media(max-width: 991px) and(min-width: 768px) {
      font-size:40px;
    }
    @media(max-width: 1199px) and(min-width: 992px) {
      font-size:40px;
    }
  }

  p{
    font-size:18px;
    color:#828282;
  }

  .faq--wrapper {
    margin-top:33px;


    .faq-item{
      background-color: #FFF;
      margin-bottom:20px;
      border-radius: 20px;
      box-shadow:2px 6px 69.5px rgba(0,0,0,0.15);
      padding:36px 128px 36px 28px;
      cursor: pointer;
      @media(max-width: 500px){
        padding:16px 9px;
      }
      @media(max-width:767px)and(min-width:501px){
        padding:16px 9px;
      }
      @media(max-width: 991px) and(min-width: 768px) {
        padding:16px 21px;
      }
      @media(max-width: 1199px) and(min-width: 992px) {
        padding:16px 21px;
      }

      &.active{
        padding-bottom: 51px;
        .header--wrapper{
          &:after{
            background-color: $successColor;
            transform: rotate(0deg);
            background-position: center;
          }
        }

        .content--wrapper{
          opacity:1;
          max-height: 100%;
          padding-top: 20px;
        }
      }

      .header--wrapper{
        display: flex;
        align-items: center;
        position: relative;

        &:after{
          content:'';
          width:67px;
          height: 67px;
          position: absolute;
          right:0px;
          background-color: gray;
          transition: 0.15s linear;
          background-image:url(../../arrow-top-white.svg);
          background-repeat: no-repeat;
          border-radius: 50%;
          transform: rotate(180deg);
          background-position: 50% 45%;

          @media(max-width: 500px){
            width:30px;
            height: 30px;
            background-size: 15px;
          }
          @media(max-width:767px)and(min-width:501px){
            width:30px;
            height: 30px;
            background-size: 15px;
          }
          @media(max-width: 991px) and(min-width: 768px) {
            width: 40px;
            height: 40px;
            background-size: 18px;
          }
          @media(max-width: 1199px) and(min-width: 992px) {
            width: 40px;
            height: 40px;
            background-size: 18px;
          }
        }


        .image {
          width: 100px;
          @media(max-width: 500px){
            display: none;
          }
          @media(max-width:767px)and(min-width:501px){
            display: none;
          }
          @media(max-width: 991px) and(min-width: 768px) {
            width: 62px;
            img{
              width: 51px;
            }
          }
          @media(max-width: 1199px) and(min-width: 992px) {
            width: 62px;
            img{
              width: 51px;
            }
          }
          @media(max-width: 1439px) and(min-width: 1200px) {
            width: 62px;
            img{
              width: 51px;
            }
          }
        }
        .index{
          color:$successColor;
          font-size:48px;
          font-weight: bold;
          margin-right: 41px;
          @media(max-width: 500px){
            font-size:24px;
          }
          @media(max-width:767px)and(min-width:501px){
            font-size:24px;
            margin: 0;
          }
          @media(max-width: 991px) and(min-width: 768px) {
            font-size: 39px;
            margin: 0px 15px 0px 0px;
          }
        }

        .content{
          margin-left:21px;
          font-size:24px;
          font-weight: bold;
          @media(max-width: 500px){
            font-size:14px;
          }
          @media(max-width:767px)and(min-width:501px){
            font-size:24px;
            margin-left: 5px;
          }
          @media(max-width: 991px) and(min-width: 768px) {
            font-size:24px;
            margin-left: 5px;
          }
          @media(max-width: 1199px) and(min-width: 992px) {
            font-size:24px;
            margin-left: 5px;
          }
          @media(max-width: 1439px) and(min-width: 1200px) {
            font-size:24px;
            margin-left: 5px;
          }
        }
      }

      .content--wrapper{
        margin-left: 235px;
        max-height: 0px;
        opacity:0;
        transition: all .15s linear;
        will-change: max-height, padding;
        padding: 0px;
        overflow: hidden;

        @media(max-width: 500px){
          margin-left: 0px;
          ol,p,ul{
            font-size:14px !important;
          }
          .content{
            padding:10px;
          }
        }
        @media(max-width:767px)and(min-width:501px){
          margin-left: 0px;
          ol,p,ul{
            font-size:14px !important;
          }
          .content{
            padding:10px;
          }
        }
        @media(max-width: 991px) and(min-width: 768px) {
          margin-left: 0px;
          ol,p,ul{
            font-size:14px !important;
          }
          .content{
            padding:10px;
          }
        }
        @media(max-width: 1199px) and(min-width: 992px) {
          margin-left: 0px;
          ol,p,ul{
            font-size:14px !important;
          }
          .content{
            padding:10px;
          }
        }
        @media(max-width: 1439px) and(min-width: 1200px) {
          margin-left: 0px;
          ol,p,ul{
            font-size:14px !important;
          }
          .content{
            padding:10px;
          }
        }

        p{
          font-weight: 500;
          color:#212121;
        }

        ol,p,ul{
          font-size:18px;
          margin: 0px 0px 15px 0px;
          padding:0px;
        }

        ol,ul{
          padding-left:20px;
          li{
            &:not(:last-child){
              margin-bottom:15px;
            }
          }
        }
      }
    }
  }
}