section.our-services{
  background-color: #262626;
  background-position: center;
  background-repeat: no-repeat;
  padding:124px 0px 90px;
  border-radius: 20px;

  margin-bottom:100px;

  @media(max-width: 500px){
    padding:30px 0px;
  }
  @media(max-width:767px)and(min-width:501px){
    padding:30px 0px;
  }
  @media(max-width: 991px) and(min-width: 768px) {
    padding:30px 0px;
  }
  @media(max-width: 1199px) and(min-width: 992px) {
    padding:90px 0px;
  }


  @media(min-width: 1500px){
    margin-left: 36px;
    margin-right: 36px;
  }


  @media(max-width:991px){
    .consultationStart{
      margin-top:15px;
    }
  }

  h2{
    margin: 0px;
    font-size:96px;
    font-weight: bold;
    color:#FFF;
    text-transform: uppercase;
    line-height: 111px;

    @media(max-width: 500px){
      font-size:36px;
    }
    @media(max-width:767px)and(min-width:501px){
      font-size: 45px;
    }
    @media(max-width: 991px) and(min-width: 768px) {
      font-size: 68px;
      line-height: 1.2;
    }
    @media(max-width: 1199px) and(min-width: 992px) {
      font-size: 68px;
      line-height: 1.2;
    }
    @media(max-width: 1439px) and(min-width: 1200px) {
      font-size: 68px;
      line-height: 1.2;
    }
  }

  p{
    color:#fff;
    font-size:24px;
    font-weight: 200;

    @media(max-width: 500px){
      font-size:16px;
    }
    @media(max-width:767px)and(min-width:501px){
      font-size:16px;
    }
    @media(max-width: 991px) and(min-width: 768px) {
      font-size:16px;
    }
    @media(max-width: 1199px) and(min-width: 992px) {
      font-size:16px;
    }

  }

  a{
    max-width: 386px;
    margin-left: auto;
    margin-right: 0px;
  }


  .icons--wrapper{
    border-radius: 20px;
    background-color: rgba(255,255,255,0.04);
    padding:48px 41px;
    margin-bottom: 177px;

    @media(max-width: 1439px){
      background: transparent;
      margin-bottom: 15px;
      padding:11px;
      .row{
        margin-bottom:15px !important;
        .col-md-6{
          &:not(:last-child){
            margin-bottom: 15px !important;
          }
        }
      }
      p{
        font-size:15px !important;
      }
    }


    .row{
     &:not(:last-child){
       margin-bottom: 34px;
     }
    }
    .col-md-6{
      display: flex;
      align-items: center;
      &:hover{
        img{
          transform: translateY(-12px);
        }
      }
    }
    img{
      max-width: 68px;
      aspect-ratio: 1;
      margin-right: 20px;
      transition: all 0.2s linear;
    }
    p{
      font-size:18px;
      font-weight: bold;
    }
  }
}