section.login-page {
  padding: 280px 0px;

  .login-page--wrapper {
    padding: 40px;
    background: $accent2;
    max-width: 600px;
    margin: 0 auto;
  }

  .error-message {
    padding: 5px;
    background: $red;
    text-align: center;
    color: #FFF;
  }

  input {
    width: 100%;
    padding: 10px 15px;
    border: 1px solid #d3d3d3;
    border-radius: 7px;
    margin-bottom: 15px;
    margin-top: 6px;

    &[type="submit"] {
      width: auto;
    }

  }

}
