form{

  input{
    width: 100%;
    border: 1px solid #F2F2F2;
    border-radius: 10px;
    padding: 23px 20px;
    font-size:14px;
    margin-bottom:10px;
    background-color: #F2F2F2;
  }

}