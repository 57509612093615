section.contact-page {
  padding: 0px 0px 80px;


  .contact-info {
    background-color: $accent2;
    padding: 30px 0px;
    margin-bottom: 40px;

    .row-flex{
      @media(max-width:500px){
       flex-direction: column;
      }
    }

    img {
      max-width: 56px;
      height: auto;
    }

    .column {
      text-align: center;
      @media(max-width:500px){
        width:100%;
      }
    }

    .contact-type {
      border-radius: 7px;
      background-color: $accent1;
      padding: 9px 7px;
      color: #FFF;
      width: 220px;
      margin: 21px auto !important;
      text-transform: uppercase;
      font-weight: 500;
      letter-spacing: 1.3px;
      line-height: 1;
      font-size: 1em;

      @media(min-width: 992px) and(max-width: 1199px) {
        padding: 6px 7px;
        width: 170px;
      }

      @media(max-width: 767px) {
        padding: 6px 7px;
        width: 92%;
        font-size: 13px;
        margin-bottom: 7px;
      }

    }

    .contact-value {

      color: #23725F;
      //font-size: 18px;
      font-size: 1.2em;
      font-weight: bold;

      &.phone {

      }

      &.mail {

      }

      &.address {

      }

      @media(min-width: 992px) and(max-width: 1199px) {
        font-size: 16px;
      }
      @media(min-width: 992px) and(max-width: 1199px) {
        font-size: 14px;
      }
      @media(max-width: 767px) {
        font-size: 13px;
      }
    }


  }

  .page-content {

    .upper-title {
      text-align: center;
      color: #0a8f6f;
      font-weight: 600;
      margin: 0px 0px 5px;
      //font-size: 16px;
      font-size: 1.1em;
    }

    h1 {
      //font-size: 44px;
      margin: 0px 0px 20px;
      text-transform: uppercase;
      letter-spacing: 1.2px;
      color: #23725F;
      text-align: center;
      position: relative;
      font-size: 2.8em;

      &:after, &:before {
        content: '';
        position: absolute;
        height: 10px;
        width: 200px;
        border-radius: 7px;
        background-color: #23725F;
        top: 19px;

        transition: all 0.2s linear;
      }

      &:before {
        left: 145px;
      }

      &:after {
        right: 145px;
      }

      @media(min-width: 992px) and(max-width: 1199px) {
        font-size: 35px;
        &:after, &:before {
          top: 16px;
          width: 174px;

        }
      }
      @media(min-width: 768px) and(max-width: 991px) {
        font-size: 31px;
        &:after, &:before {
          top: 13px;
          width: 41px;
        }
      }
      @media(max-width: 767px) {
        font-size: 27px;
        &:after, &:before {
          display: none !important;
        }
      }
    }
  }


  form {
    max-width: 768px;
    margin: 50px auto 0px;

    input.input-validation--error{
      border-radius: 10px 10px 0px 0px;
      border-color: red;
    }

    span.input-validation--error{
      background-color: red;
      color: #FFF;
      margin: 0;
      text-align: center;
      padding: 4px;
      border-radius: 0px 0px 10px 10px;
    }

    label {
      display: block;

      span {
        margin: 17px 0px 5px;
        display: block;
        text-transform: uppercase;
        letter-spacing: 1.2px;
        font-size: 12px;
      }
    }

    textarea {
      resize: vertical;

    }

    input, textarea {
      background-color: rgba(255, 255, 255, 0.05);
      color: #000;
      border-radius: 10px;
      width: 100%;
      font-size: 18px;
      border: 1px solid #e5e5e5;
      padding: 23px 20px;
      margin-bottom: 0px;
      background-color: #FFF;
      font-family: inherit;
    }

    input[type="submit"], button[type="submit"] {
      cursor: pointer;
      width: auto;
      padding: 23px 30px;
      margin-top: 22px;
      font-size: 18px;
      color: #FFF;
      background-color: $successColor;
      border: 1px solid $successColor;
      border-radius: 63px;

    }
  }

}

