
.car-brand--slider {
  margin: 25px 0px 25px;
  padding-top: 25px;
  list-style-type: none;
  overflow-x: hidden;

  .car-brand--single {
    margin-right: 65px;
    width:115px;
    height: 115px;

    @media(max-width: 500px){
      width:72px;
      height: 72px;
      margin-right: 6px;
    }
    @media(max-width:767px)and(min-width:501px){
      width:95px;
      height: 95px;
      margin-right: 6px;
    }


    &:hover {
      img {
        filter: grayscale(0%);

      }
    }

    img {
      height: 115px;
      max-width: 115px;
      object-fit: contain;
      padding: 12px;
      border: 1px solid transparent;
      transition: all 0.3s linear;
      border-radius: 8px;
      filter: grayscale(100%);


      @media(min-width: 501px)and(max-width:767px){
        max-width: 95px;
        height: 95px;
      }
      @media(max-width: 500px) {
        max-width: 72px;
        height: 72px;
      }
    }
  }
}
