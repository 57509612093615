section.cta--wrapper{
  background-color: $secondaryColor;
  padding: 133px 0px 89px;
  border-radius: 20px 20px 0px 0px;
  position: relative;
  bottom: -18px;

  @media(max-width: 500px){
    padding: 30px 0px;
    .row-flex{
      flex-direction: column;
    }
  }
  @media(max-width:767px)and(min-width:501px){
    padding: 30px 0px 50px;
    .row-flex{
      flex-direction: column;
    }
  }
  @media(max-width: 991px) and(min-width: 768px) {
    padding: 30px 0px 50px;
    .row-flex{
      flex-direction: column;
    }
  }

  h2,p{
    color:#FFF;
  }

  h2{
    font-size:64px;
    font-weight: bold;
    margin-bottom:20px;
    @media(max-width: 500px){
      font-size:36px;
    }
    @media(max-width:767px)and(min-width:501px){
      font-size:36px;
    }
    @media(max-width: 991px) and(min-width: 768px) {
      font-size:36px;
      text-align: center;
    }
    @media(max-width: 1199px) and(min-width: 992px) {
      font-size:36px;
      text-align: center;
    }
    @media(max-width: 1365px) and(min-width: 1200px) {
      font-size:56px;
      text-align: center;
    }
    @media(max-width: 1439px) and(min-width: 1366px) {
      font-size:56px;
    }
  }

  p{
    font-size:18px;
    line-height: 28px;
    @media(max-width: 991px) and(min-width: 768px) {
      text-align: center;
    }
  }

  .form--wrapper{
    text-align: center;
    @media(max-width: 991px) and(min-width: 768px) {
     margin-top:30px;
    }

    h3{
      color:#FFF;
      font-size:36px;
      font-weight: bold;
      margin:0px 0px 4px;
      @media(max-width: 500px){
        text-align: left;
        margin-top:30px;
      }

      @media(max-width:767px)and(min-width:501px){
        text-align: left;
        margin-top:30px;
      }

      @media(max-width: 991px) and(min-width: 768px) {
        font-size:30px;
        text-align: center;
      }
      @media(max-width: 1199px) and(min-width: 992px) {
        font-size:30px;
        text-align: center;
      }
    }

    p{
      font-size:18px;
      @media(max-width: 500px){
        text-align: left;
      }
      @media(max-width:767px)and(min-width:501px){
        text-align: left;
      }
      @media(max-width: 991px) and(min-width: 768px) {
        text-align: center;
      }
      @media(max-width: 1199px) and(min-width: 992px) {
        text-align: center;
      }
    }

    small{
      margin-top:24px;
      font-size:14px;
      color:#FFF;
      display: block;
    }

    h3, p, small, form{
      max-width: 509px;
      margin-left:auto !important;
      margin-right: auto !important;
      @media(max-width:767px)and(min-width:501px){
        max-width: 100%;
      }
      @media(max-width: 991px) and(min-width: 768px) {
        max-width: 100%;
      }
    }
  }


  form{
    .form-row{

    }
    input{
      background-color: rgba(255,255,255,0.05);
      padding:25px 25px;
      color:#FFF;
      border-radius: 10px;
      border-color: transparent;
      margin-bottom:10px;
      width:100%;
      font-size:18px;
      &::placeholder{
        font-size:18px;
        color:rgba(255,255,255,0.76);
      }
    }
  }
}


