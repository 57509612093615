.car-grid{
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-column-gap: 20px;
  grid-row-gap: 25px;
  margin-bottom:100px;

  @media(max-width: 500px){
    grid-template-columns: 1fr;
    grid-gap: 11px;
  }
  @media(max-width:767px)and(min-width:501px){
    grid-template-columns: 1fr 1fr;
    grid-gap: 11px;
  }
  @media(max-width:991px)and(min-width:768px){
    grid-template-columns: 1fr 1fr;
    grid-gap: 20px;
  }
  @media(max-width: 1199px) and(min-width: 992px) {
    grid-gap: 10px;
  }


  .single-item{
    background-color: #FFF;
    box-shadow:2px 6px 69.5px rgba(0,0,0,0.15);
    border-radius: 20px;

    .image--wrapper{
      position: relative;
      border-radius:20px;
      max-height: 287px;
      overflow: hidden;

      img{
        border-radius:20px;
        height: 100%;
        width: 100%;
      }

      .price{
        position: absolute;
        bottom:0px;
        left:0px;
        right:0px;
        padding:10px 20px;

        text-align: left;
        font-size:24px;
        font-weight: bold;
        color:#FFF;
        z-index: 2;
        border-radius:0px 0px 20px 20px;
        box-shadow: inset 0px -20px 20px 13px #00000057;
      }
    }

    .content--wrapper{
      padding:20px;

      h3{
        margin-bottom: 30px;
        font-size:18px;
        font-weight: bold;
        margin-top:0px;
      }

      .meta{
        &:not(:last-child){
          margin-bottom:10px;
        }

        span{
          display: block;
          &.name{
            font-size: 14px;
            color: #909090;
            font-weight: 400;

          }

          &.value{
            color: #212121;
            font-size:18px;
            font-weight: 400;
            display: flex;
            align-items: flex-end;
            @media(max-width:767px)and(min-width:501px){
              font-size:17px;
            }

            img{
              margin-top:10px;
              margin-right: 7px;
              max-width: 30px;
              height: auto;
            }
            em{
              font-style: normal;
              font-size: 12px;
              text-transform: uppercase;
            }
          }
        }
      }

    }


  }
}

.pagination-wrapper{
  margin-top: 100px;
  margin-bottom:127px;
  nav.pagination{
    ul{
      list-style-type: none;
      display: flex;
      align-items: center;
      justify-content: center;

      li{
        &:not(:last-child){
          margin-right: 18px;
        }
        a{
          font-size: 18px;
          color:#8a8a8a;
          font-weight: 500;
          padding: 8px 3px;
          border-radius: 50%;
          transition: all 0.3s linear;
          width: 38px;
          display: block;
          text-align: center;

          &.active, &:hover{
            background-color: #0A8F6F;
            color:#FFF;
          }


          &.arrow{
            background-color: transparent !important;

          }
        }
      }
    }

  }
}