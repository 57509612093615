
$fontText : "Montserrat", sans-serif;
$fontHeader : "Montserrat", sans-serif;
$fontSpecial : 'Caveat', cursive;

$mainColor: #2D4648;
$secondaryColor: #122E31;
$successColor: #00C259;


$accent1 : #0a8f6f;
$accent2 : #f5f0f0;
$red : #ff4c4c;
$secondaryFontColor: #707070;

$sectionPaddings : 80px 0px;
