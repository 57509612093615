section.how-it-works-page {
  padding: 80px 0px 0px;

  h1, h2, h3, h4, h5 {
    color: #707070;
    text-transform: uppercase;
  }

  .page-wrapper {
    h1 {
      color: #707070;
      text-align: center;
      text-transform: uppercase;
      font-size: 24px;
      margin-bottom: 64px;

    }
  }

  .content-wrapper {
    margin-top: 40px;
    padding: 40px 0px;
    background-color: #F3EFEF;
    p{
      margin-bottom: 34px;
    }

  }

  .icons-wrapper {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    grid-column-gap: 15px;

    @media (max-width: 500px) {
      grid-column-gap: 0px;
      grid-template-columns: 1fr;
      grid-row-gap: 20px;
    }

    @media (min-width: 501px) and (max-width: 767px) {
      grid-column-gap: 10px;
      grid-template-columns: 1fr 1fr;
      grid-row-gap: 20px;
    }

    @media (min-width: 768px) and (max-width: 991px) {
      grid-column-gap: 10px;
      grid-template-columns: 1fr 1fr 1fr;
      grid-row-gap: 20px;
    }



    .column {
      text-align: center;

      img {

      }

      p {
        line-height: 1.5;
      }
    }


  }

}
