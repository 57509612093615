footer {
  background-color: $mainColor;
  padding-top: 97px;
  // padding-bottom: 86px;
  border-radius: 20px 20px 0px 0px;
  position: relative;


  .first-col{
    img{
      max-width: 364px;
      margin-bottom: 15px;
      width: 100%;
    }

    p{
      color:#FFF;
      font-size:18px;
      font-weight: 300;
      line-height: 1.6 !important;
    }
  }

  .contact-col{
    .phone{
      @media(max-width: 1199px) and(min-width: 992px) {
       font-size:26px;
      }
      @media(min-width: 1200px) and(max-width: 1365px) {
        font-size:26px;
      }
      @media(max-width: 1439px) and(min-width: 1366px) {
        font-size:26px;
      }
    }
  }

  .menu-col{
    padding-left: 80px;
    @media(max-width:500px){
      padding: 12px;
      margin-bottom: 20px;
      margin-top: 20px;
    }
    @media(max-width:767px)and(min-width:501px){
      padding: 12px;
      margin-bottom: 20px;
      margin-top: 20px;
    }
    @media(max-width: 991px) and(min-width: 768px) {
      padding: 12px;
      margin-bottom: 20px;
      margin-top: 20px;
    }
    @media(max-width: 1199px) and(min-width: 992px) {
      padding: 12px;
      margin-bottom: 20px;
      margin-top: 20px;
    }
  }


  nav{
    ul{
      list-style-type: none;
      padding:0px;
      margin:0px;
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-row-gap: 13px;
      @media(max-width: 500px){
        grid-template-columns: 1fr;
      }
      @media(max-width:767px)and(min-width:501px){
        grid-template-columns: 1fr;
      }
      @media(max-width: 991px) and(min-width: 768px) {
        grid-template-columns: 1fr 1fr;
      }

      li{
        color:#FFF;
        font-size:18px;
        text-transform: uppercase;

        a{
          color:inherit;
        }
      }
    }
  }

  .contact-col{
    text-align: left;
  }

  .phone,
  .email,
  .socials,
  .address{
      color:#FFF;
  }

  .address{
    font-size:24px;
    font-weight: bold;
    margin-bottom:11px;
  }

  .email{
    font-size:18px;
    margin-bottom:45px;

  }

  .phone{
    font-size:30px;
    color:#FFF;
    font-weight: bold;margin-bottom:11px;
  }

  .socials{
    display: flex;
    flex-wrap: wrap;
    // justify-content: end;
    @media(max-width: 500px){
      justify-content: space-between;
      padding-left: 20px;
      padding-right: 20px;
    }

    a{
      &:not(:last-child){
       img{
         margin-right: 28px;
       }
      }
    }
    img{

    }
  }


  .privacy{
    margin-top:86px;
    background-color: $mainColor;
    border-top: 1px solid rgba(255, 255, 255, 0.08);
    padding:33px 0px;
    color:#FFF;

    @media(max-width:500px){
      .links{
        text-align: center !important;
      }
      p,a{
        text-align: center !important;
      }
    }

    @media(max-width:767px)and(min-width:501px){
      .links{
        text-align: center !important;
      }
      p,a{
        text-align: center !important;
      }
    }

    @media(max-width: 991px) and(min-width: 768px) {
      .links{
        text-align: center !important;
      }
      p,a{
        text-align: center !important;
      }
    }


    .links{
      text-align: right;
    }

    p,a{
      line-height: 1;
      margin:0px !important;

    }


    a{
      text-align: right;

    }
  }
}
