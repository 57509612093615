.page-not-found {
  padding: 200px 0px;

  h1 {
    text-align: center;
    margin-bottom: 40px;
  }

  a {
    display: table;
    margin: 0 auto;
    text-transform: uppercase;
    letter-spacing: 1.6px;
  }
}
