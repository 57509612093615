
.container {
  margin: 0 auto;
  // transition: max-width 0.2s linear;
  transition: all 0.2s linear;

  @media(min-width: 1520px) {
    max-width: 1520px;
  }

  @media(min-width: 1200px) and (max-width:1519px) {
    max-width: 1140px;
  }

  @media(min-width: 992px) and(max-width: 1199px) {
    max-width: 980px;
  }

  @media(min-width: 768px) and(max-width: 991px) {
    max-width: 640px;
  }

  @media (min-width: 501px) and (max-width: 767px) {
    max-width: 100%;
    margin-left: 0px;
    margin-right: 0px;
    padding-left: 10px;
    padding-right: 10px;
  }

  @media (max-width: 500px) {
    max-width: 100%;
    margin-left: 0px;
    margin-right: 0px;
    padding-left: 10px;
    padding-right: 10px;
  }
}

.row-flex {
  display: flex;
  flex-wrap: wrap;

  .column {
    flex: 1;
  }

  .column-1-3 {
    width: 33%;
  }
}
