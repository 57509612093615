section.our-offer{
  margin-top:100px;
  margin-bottom:100px;

  .our-offer--wrapper{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 20px;

    @media(max-width: 500px){
      grid-template-columns: 1fr;
      grid-gap: 11px;
    }
    @media(max-width:767px)and(min-width:501px){
      grid-template-columns: 1fr;
      grid-gap: 11px;
    }
    @media(max-width:991px)and(min-width:768px){
      grid-template-columns: 1fr 1fr;
      grid-gap: 20px;
    }
    @media(max-width: 1199px) and(min-width: 992px) {
      grid-gap: 10px;
    }
    @media(max-width: 1365px) and(min-width: 1200px) {
      grid-gap: 10px;
    }
  }


  .item{
    width:100%;
    background-color: #FFF;
    padding:28px;
    border-radius:20px;
    box-shadow:2px 6px 69.5px rgba(0,0,0,0.15);

    h2{
      text-transform: uppercase;
      font-size:36px;
      font-weight: 400;
      strong{
        display: block;
        font-weight: bold;
      }
    }

    a{
      @media(max-width:767px)and(min-width:501px){
        display: block;
        text-align: center;
      }
      @media(max-width:991px)and(min-width:768px){
        display: block;
        text-align: center;
      }
    }

    img{
      max-width: 100%;
      height: auto;
    }

    a.btn{
      margin-top:14px;
      background-color: $successColor;
      border:4px solid $successColor;
      color:#FFF;
      display: block;
      width: 100%;
      border-radius:63px;
      font-size: 18px;
      font-weight: bold;
      text-align: center;
      padding:20px 10px;
      transition: all 0.3s linear;


      &:hover{
        background-color: transparent;
        color:$successColor;
      }

      @media(max-width:500px){
        padding: 11px 10px !important;
      }
      @media(max-width:767px)and(min-width:501px){
        padding: 15px 10px !important;
      }
      @media(max-width:991px)and(min-width:768px){
        padding: 17px 10px !important;
      }
      @media(max-width: 1199px) and(min-width: 992px) {
        padding: 10px 10px !important;
      }
    }

  }


}