section.single-car-section {

  .single-car--information-wrapper {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 148px;
    @media(max-width: 500px){
      grid-template-columns: 1fr;
    }
    @media(max-width:767px)and(min-width:501px){
      grid-template-columns: 1fr;
    }
    @media(max-width: 991px) and(min-width: 768px) {
      grid-template-columns: 1fr;
    }
    @media(max-width: 1199px) and(min-width: 992px) {
      grid-template-columns: 1fr;
    }
    @media(max-width: 1439px) and(min-width: 1200px) {
      grid-column-gap: 20px;
    }


    .car-slider--wrapper {
      img {
        border-radius: 20px;
        width: 100%;
        height: auto;
      }

      .car-gallery {
        .car-image {
          width: 100%;


          img {
            width: 100%;
          }
        }
      }

      .car-thumbnails {
        margin-top: 10px;

        .car-image {

          &.is-selected {
            img {
              border-color: $successColor;
            }
          }

          img {
            margin-right: 5px;
            max-width: 129px;
            border: 5px solid grey;
            @media(max-width: 500px){
              max-width: 85px;
            }
            @media(max-width: 991px) and(min-width: 768px) {
              max-width: 85px;
            }
            @media(max-width: 1439px) and(min-width: 1200px) {
              max-width: 85px;
            }
          }
        }
      }
    }

    .car-info--wrapper {
      h1 {
        font-size: 36px;
        font-weight: bold;
        margin: 0px 0px 20px;
        text-wrap: balance;
        @media(max-width: 500px){
          font-size: 26px;
          margin-top:20px;
        }
        @media(max-width:767px)and(min-width:501px){
          font-size: 26px;
          margin-top:20px;
        }
        @media(max-width: 991px) and(min-width: 768px) {
          font-size: 30px;
          margin-top:20px;
        }
        @media(max-width: 1199px) and(min-width: 992px) {
          margin-top:20px;
        }

      }

      .price-wrapper {
        border: 1px solid #e3e3e3;
        border-radius: 14px;
        display: inline-flex;
        align-items: center;
        padding: 17px 13px;
        margin-bottom: 17px;
        cursor: pointer;

        @media(max-width: 500px){
          display: flow;
        }
        @media(max-width:767px)and(min-width:501px){
          display: flow;
        }
        @media(max-width: 991px) and(min-width: 768px) {
          display: flow;
        }
        @media(max-width: 1199px) and(min-width: 992px) {
          display: flow;
        }

        .more-details-tooltip {
          display: none;
        }


        .price {
          color: $successColor;
          font-size: 30px;
          font-weight: 600;
          margin-right: 16px;

        }

        p {
          font-size: 14px;
          margin: 0px !important;
          font-weight: 400;
        }
      }

      .meta-wrapper {
        div {
          display: flex;
          align-items: center;
          @media(max-width: 500px){
            justify-content: space-between;
          }
          @media(max-width:767px)and(min-width:501px){
            justify-content: space-between;
          }
          @media(max-width: 991px) and(min-width: 768px) {
            justify-content: space-between;
          }

          &:not(:last-child) {
            margin-bottom: 18px;
          }

          img {
            width: 25px;
            height: auto;
            margin-right: 8px;
          }

          span {
            font-size: 18px;
            @media(max-width: 500px){
              font-size: 15px;
            }
            @media(max-width:767px)and(min-width:501px){
              font-size: 16px;
            }
            @media(max-width: 991px) and(min-width: 768px) {
              font-size: 16px;
            }

            &.key {
              font-weight: bold;
              margin-right: 18px;
              display: flex;
              align-items: center;

            }

            &.value {

            }
          }
        }
      }

      .buttons-wrapper {
        display: grid;
        grid-template-columns: 252px 252px;
        align-items: center;
        margin-top: 25px;
        @media(max-width: 500px){
          grid-template-columns: 1fr;
          grid-gap: 10px;
        }
        @media(max-width:767px)and(min-width:501px){
          grid-template-columns: 1fr;
          grid-gap: 10px;
        }
        @media(max-width: 991px) and(min-width: 768px) {
          grid-template-columns: 1fr 1fr;
          grid-gap: 10px;
        }


        a, button {
          padding: 24px 10px;

          @media(max-width: 500px){
            padding: 19px 10px !important;
          }
          @media(max-width:767px)and(min-width:501px){
            padding: 19px 10px !important;
          }
          @media(max-width: 991px) and(min-width: 768px) {
            padding: 19px 10px !important;
          }
        }

        .button-secondary {
          text-align: center;
          display: flex;
          align-items: center;
          justify-content: center;
          margin-left: 18px;

          @media(max-width: 500px){
            margin-left: 0px;
          }
          @media(max-width:767px)and(min-width:501px){
            margin-left: 0px;
          }
          @media(max-width: 991px) and(min-width: 768px) {
            margin-left: 0px;
            img{
              margin-left:4px !important;
            }
          }

          img {
            width: 27px;
            height: 27px;
            margin-left: 36px;
            @media(max-width: 500px){
              margin-left: 4px;
            }
            @media(max-width:767px)and(min-width:501px){
              margin-left: 4px;
            }
          }
        }
      }
    }
  }


  section.car-attributes {
    margin-top: 82px;

    .attribute-group {
      h3 {
        font-size: 24px;
        font-weight: bold;
        margin: 0px 0px 30px 0px;
        @media(max-width: 500px){
          font-size: 22px;
        }
        @media(max-width:767px)and(min-width:501px){
          font-size: 22px;
        }
        @media(max-width: 991px) and(min-width: 768px) {
          font-size: 22px;
        }
        @media(max-width: 991px) and(min-width: 768px) {
          font-size: 22px;
        }
      }

      .attributes {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        grid-column-gap: 20px;
        grid-row-gap: 20px;
        margin-bottom: 40px;
        @media(max-width: 500px){
          grid-template-columns: 1fr;
        }
        @media(max-width:767px)and(min-width:501px){
          grid-template-columns: 1fr;
        }
        @media(max-width: 991px) and(min-width: 768px) {
          grid-template-columns: 1fr 1fr;
        }


        .attribute {
          background-color: #FFF;
          border-radius: 10px;
          padding: 20px 18px 20px 28px;
          display: flex;
          align-items: center;
          justify-content: space-between;
          cursor: pointer;
          gap: 20px;
          @media(max-width: 500px){
            padding: 10px;
          }
          @media(max-width:767px)and(min-width:501px){
            padding: 10px;

          }
          @media(max-width: 991px) and(min-width: 768px) {
            padding: 10px;
          }
          @media(max-width: 1199px) and(min-width: 992px) {
            padding: 10px;
          }


          .content {
            display: flex;
            align-items: center;
          }

          img.info {
            width: 26px;
            height: 26px;

          }

          img.icon {
            width: 50px;
            height: 50px;
            margin-right: 30px;

          }

          p {
            font-size: 18px;
            font-weight: bold;
            margin: 0px !important;
            @media(max-width: 500px){
              font-size: 15px;
            }
            @media(max-width:767px)and(min-width:501px){
              font-size: 15px;
            }
            @media(max-width: 991px) and(min-width: 768px) {
              font-size: 15px;
            }
            @media(max-width: 1199px) and(min-width: 992px) {
              font-size: 16px;
            }
          }

          .tooltip {
            &.is-visible {
              display: block;
            }

            position: absolute;
            display: none;
            padding: 20px;
            border-radius: 10px;
            box-shadow: 2px 6px 69.5px rgba(0, 0, 0, 0.15);
            background-color: #FFF;

            img {
              width: 100%;
              margin-bottom: 20px;
            }

            &:after {
              content: '';
              display: block;
              width: 0;
              height: 0;
              position: absolute;
              border-top: 15px solid #FFF;
              border-bottom: 15px solid transparent;
              border-right: 15px solid transparent;
              border-left: 15px solid transparent;
              bottom: -30px;
              left: 0px;
              right: 0px;
              margin-right: auto;
              margin-left: 36px;
            }
          }
        }
      }
    }
  }

  section.related-cars {
    h3 {
      font-size: 50px;
      font-weight: bold;
      @media(max-width: 500px){
        font-size: 33px;
      }
      @media(max-width:767px)and(min-width:501px){
        font-size: 33px;
      }
      @media(max-width: 991px) and(min-width: 768px) {
        font-size: 33px;
      }
      @media(max-width: 1199px) and(min-width: 992px) {
        font-size: 33px;
      }
    }
  }

  div.single-car--description {
    margin-bottom: 50px;

    h3 {
      font-size: 36px;
      font-weight: bold;
      margin: 0px 0px 14px;
      @media(max-width: 500px){
        font-size: 33px;
      }
      @media(max-width:767px)and(min-width:501px){
        font-size: 33px;
      }
      @media(max-width: 991px) and(min-width: 768px) {
        font-size: 33px;
      }
      @media(max-width: 1199px) and(min-width: 992px) {
        font-size: 33px;
      }
    }

    .content {
      p {
        font-size: 18px;
        line-height: 28px !important;
        font-weight: 400;
        @media(max-width: 500px){
          font-size: 15px;
        }
        @media(max-width:767px)and(min-width:501px){
          font-size: 15px;
        }
        @media(max-width: 991px) and(min-width: 768px) {
          font-size: 15px;
        }
        @media(max-width: 991px) and(min-width: 768px) {
          font-size: 15px;
        }
      }
    }
  }
}

.tippy-arrow {
  color: #FFF;
}

.tippy-box {
  background-color: #FFF;
  border-radius: 10px;
  padding: 20px;
  box-shadow: 2px 6px 69.5px rgba(0, 0, 0, 0.15);
  color: #212121;
  max-width: 485px !important;

  .tippy-content {
    padding: 0px !important;

    img {
      border-radius: 3px;
      width: 100%;
      height: auto;
      margin-bottom: 19px;
    }
  }

}