header {
  background-color: $secondaryColor;
  transition: all 0.4s ease-in-out;
  position: relative;


  .container-fluid{
    padding-left: 58px;
    padding-right: 58px;
    @media(max-width:500px){
      padding-left: 12px;
      padding-right: 12px;
    }
  }

  &.is-sticky {
    //position: fixed;
    //position: sticky;
    left: 0;
    right: 0;
    z-index: 15;
    top:0px;

    .header-wrapper{
      padding: 6px 0px;
    }

    .search-input-wrapper{
      padding: 16px 0px;
    }
  }

  .header-wrapper {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    padding: 26px 0px 50px;
    transition: all 0.4s ease-in-out;

    .logo-wrapper {
      &.is-open {

      }

      a {
        img {
          max-width: 200px;
        }
      }
    }

    .hamburger-menu{
      &.is-open{
        opacity:0
      }
    }

    .menu-wrapper {
      flex: 1;
      @media(min-width:1440px)and(max-width:1700px){
        width: 85%;
      }

      nav.main-menu {
        ul {
          padding: 0;
          margin: 0;
          display: flex;
          flex-wrap: wrap;
          text-align: right;
          justify-content: flex-end;

          li {
            &:not(:last-child) {
              margin-right: 56px;
            }
            list-style-type: none;
            position: relative;
            padding: 10px 0px;

            &:hover{
              &>ul{
                display: block;
              }
            }

            ul{
              display: none;
              position: absolute;
              background: #36c259;
              padding: 10px 20px;
              border-radius: 10px;
              width: 337px;
              text-align: left;
              justify-content: flex-start;
              top: 36px;
              z-index: 3;

              li{
                padding:15px 0px;
                transition: all 0.1s linear;

                &:not(:last-child) {
                  // margin-bottom:14px;
                }

                &:hover {
                  transform: translateX(10px);
                }

                a{
                  padding:0px;
                  &:hover {
                    color: #FFF;
                  }
                }
              }
            }

            a {
              color: #FFF;
              text-decoration: none;
              text-transform: uppercase;
              transition: 0.2s linear;
              font-size: 18px;
              font-weight: 500;

              @media(max-width: 1199px) and(min-width: 992px) {
                font-size: 16px;
              }
              @media(max-width: 1365px) and(min-width: 1200px) {
                font-size: 16px;
              }
              @media(min-width: 1440px)and(max-width:1700px){
                font-size: 16px;
              }

              &:hover {
                color: $accent1
              }
            }
          }
        }

        @media(max-width: 991px) {
          display: none;
        }
      }


      @media(max-width: 991px) {
        text-align: right;
      }
      @media(min-width: 992px) and(max-width: 1199px) {
        nav {
          ul {
            li {
              &:not(:last-child) {
                margin-right: 20px !important;
              }

              a {
                font-size: 0.8em;
                //font-size: 13px;
              }
            }
          }
        }
      }
    }
  }
}

button.hamburger-menu {
  @media(min-width: 992px) {
    display: none;
  }

  -webkit-appearance: none;
  border: none;
  background-color: transparent;
  position: relative;
  height: 29px;
  width: 30px;
  padding: 0px;
  cursor: pointer;
  outline-color: transparent;

  &.is-open {
    .hamburger-top {
      position: relative;
      top: 8px;
      transform: rotate(45deg);
    }

    .hamburger-middle {
      opacity: 0;
    }

    .hamburger-bottom {
      transform: rotate(-45deg);
      position: relative;
      top: -10px;
    }
  }
  &:focus {
    border: none !important;
    outline: none !important;

  }


  span {
    &.hamburger {
      opacity: 1;
      display: block;
      width: 100%;
      background-color: $accent1;
      height: 4px;
      transition: all 0.1s linear;

      &:not(:first-child) {
        margin-top: 5px;
      }

    }

    &.hamburger-top {

    }

    &.hamburger-middle {

    }

    &.hamburger-bottom {

    }

  }
}

@media (min-width: 992px) {
  .mobile-side-menu {
    display: none;
  }
}

.mobile-side-menu {
  position: fixed;
  left: 0;
  top: 0px;
  width: 320px;
  height: 100%;
  background: #1a4044;
  z-index: 15;
  transform: translate(-100%, 0px);
  transition: all 0.2s ease-in-out;
  opacity: 0;

  &.is-open {
    opacity: 1;
    transform: translate(0%, 0px);
  }

  .contact-info {
    p {
      background: #122e31;
      padding: 20px;
      text-transform: uppercase;
      letter-spacing: 1.2px;
      font-weight: bold;
      color: #FFF;
    }

    ul {
      margin: 0px 0px 0px;
      padding: 0;

      li {
        list-style-type: none;

        a {
          color: #FFF;
          padding: 19px 20px;
          display: block;
          border-bottom: 1px solid #122e31;
          text-transform: uppercase;
          letter-spacing: 1.2px;
        }
      }
    }
  }

  .mobile-main-menu--nav {
    margin-top:40px;
    ul {
      margin: 0px 0px 0px;
      padding: 0;

      li {
        list-style-type: none;

        a {
          color: #FFF;
          padding: 19px 20px;
          display: block;
          border-bottom: 1px solid #122e31;
          text-transform: uppercase;
          letter-spacing: 1.2px;
        }
      }
    }
  }

  .hamburger-menu{
    right: 20px;
    position: absolute;
    top: 20px;
  }

}

.mobile-side-menu--overlay {
  @media(min-width: 992px) {
    display: none;
  }

  &.is-open {
    opacity: 1;
    display: block;
  }

  top: 0;
  opacity: 0;
  display: none;
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: 11;
  background-color: rgba(0, 0, 0, 0.7);
}
