body {
  margin: 0px;
  font-family: $fontText;
  font-size: 14px;
  background-color: #F5F5F5;

  &.form_bar_is_open{
    overflow: hidden;
  }
}

.page-wrapper{
  overflow-x:hidden;
}

* {
  box-sizing: border-box;
}

a {
  text-decoration: none;
  color: inherit;

  &:hover {
    color: inherit;
  }
}

.title-bar {
  background-color: $mainColor;
  color: #FFF;
  padding: 34px 0px;

  h2 {
    font-weight: 300;
    margin: 0px;
    text-transform: uppercase;
    letter-spacing: 1.9px;
    //font-size: 14px;
    font-size: 0.9em;
  }
}

h2, .h2 {
  font-size: 1.5em
}

h3, .h3 {
  font-size: 1.25em;
}

.img-responsive {
  max-width: 100%;
  height: auto;
}


.swiper-pagination {
  position: absolute;
  text-align: center;
  transition: .3s opacity;
  transform: translate3d(0, 0, 0);
  z-index: 10;

  bottom: 10px;
  left: 0;
  width: 100%;

  .swiper-pagination-bullet-active {
    background-color: $mainColor !important;
    opacity: 1 !important;
  }

  .swiper-pagination-bullet {
    cursor: pointer;
    width: 8px;
    height: 8px;
    display: inline-block;
    border-radius: 100%;
    background-color: #000;
    opacity: .2;

    margin: 0 4px;

  }
}

.swiper-button-next {
  background-image: url(../../right-arrow.svg);
}

.swiper-button-prev {
  background-image: url(../../left-arrow.svg);
}

.swiper-button-next, .swiper-button-prev {
  background-repeat: no-repeat;
  background-position: center;
  background-color: #274144;
  border-radius: 29px;
  width: 40px !important;
  background-size: 17px;
  height: 40px !important;

  &:after {
    content: none !important;
  }
}

p{
  text-indent: 0px !important;
  line-height: 1.2 !important;
  margin: 0px 0px 10px !important;
}

table{
  margin: 0px !important;
  width: 100% !important;

  tbody{
    tr{
      height: auto !important;
    }
    td {
      width: auto !important;
      padding: 7px !important;
      height: auto !important;
    }
  }
}
