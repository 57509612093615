.button-secondary{
  color:#000;
  padding:25px 57px;
  border:4px solid #CFCFCF;
  border-radius:63px;
  font-size:18px;

  transition: all 0.3s linear;

  &:hover{
    border-color: $successColor;
  }

  @media(max-width:500px){
    padding: 11px 10px !important;
  }

  @media(max-width:991px)and(min-width:768px){
    padding: 15px 20px;
    text-align: center;
  }
}


.button-green{
  background-color: $successColor;
  border:4px solid $successColor;
  color:#FFF;
  display: block;
  width: 100%;
  border-radius:63px;
  font-size: 18px;
  font-weight: bold;
  text-align: center;
  padding:20px 10px;
  transition: all 0.3s linear;
  cursor: pointer;

  &:hover{
    background-color: transparent;
    color:$successColor;
  }
}