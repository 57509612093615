section.hero {
  padding: 87px 0px 75px;
  position: relative;
  top: -17px;
  background: whitesmoke;
  border-radius: 20px 20px 0px 0px;

  .grid-row {
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-items: center;
    position: relative;

    @media(max-width: 500px){
      grid-template-columns: 1fr;
    }
    @media(max-width:767px)and(min-width:501px){
      grid-template-columns: 1fr;
    }
    @media(max-width:991px)and(min-width:768px){
      grid-template-columns: 1fr;
    }


    .image-column {
      position: relative;
      img {
        position: relative;
        z-index: 2;

        @media(max-width: 500px){
          max-width: 100%;
        }
        @media(max-width:767px)and(min-width:501px){
          max-width: 100%;
        }
        @media(max-width:991px)and(min-width:768px){
          max-width: 100%;
        }
      }

      @media(max-width: 500px){
        display:none;
      }
      @media(max-width:767px)and(min-width:501px){
        display:none;
      }
      @media(max-width:991px)and(min-width:768px){
        display:none;
      }
    }

    .decoration {
      position: absolute;
      color: #FFF;
      font-size: 238px;
      font-weight: 800;
      z-index: 0;
      letter-spacing: -9px;

      &.decoration-1 {
        left: -77px;
        top: -21px;
      }

      &.decoration-2 {
        top: 135px;
        left: 49px;
      }

      @media(max-width: 500px){
        display: none;
      }

    }

    h1 {
      font-size: 64px;
      font-weight: 800;
      text-transform: uppercase;
      margin: 0px 0px 5px;
      line-height: 71px;
      position: relative;
      z-index: 2;
      transition: all 0.2s linear;

      span {
        color: $successColor;
      }

      @media(max-width: 500px){
        font-size: 33px;
        line-height: 1.1;
      }
      @media(max-width:767px)and(min-width:501px){
        font-size: 51px;
        line-height: 1.1;
      }
    }

    p {
      @media(max-width: 500px){
        font-size: 21px;
      }
      @media(max-width:767px)and(min-width:501px){
        font-size: 21px;
      }

      font-size: 24px;
      font-weight: 400;
      margin: 0px 0px 10px;
    }


    a{
      max-width: 252px;
    }

    .socials {
      margin-top: 83px;
      display: flex;
      flex-wrap: wrap;
      align-items: center;

      a {
        &:not(:last-child) {
          img {
            margin-right: 29px;
          }
        }
      }

      img {
        filter: grayscale(1);
        transition: all 0.3s linear;

        &:hover {
          filter: grayscale(0);
        }
      }
    }
  }
}