
.search-form-wrapper {
  // position: relative;
  margin-left: 73px;
  transition: all 0.2s linear;
  @media(max-width:500px){
    margin:10px 0px 0px 0px;
    order:3;
    width:100%;
  }

  @media(max-width:767px) and (min-width:501px){
    margin:10px 0px 0px 0px;
    order:3;
    width:100%;
  }

  @media(max-width: 991px) and (min-width: 768px) {
    margin:10px 0px 0px 0px;
    order:3;
    width:100%;
  }

  @media(max-width: 1199px) and (min-width: 992px) {
    margin:10px 0px 0px 0px;
    order:3;
    width:100%;
  }

  @media(max-width: 1365px) and (min-width: 1200px) {
    margin:10px 0px 0px 0px;
    order:3;
    width:100%;
  }

  @media(max-width: 1700px) and (min-width: 1366px) {
    margin:10px 0px 0px 0px;
    order:3;
    width:100%;
  }

  @media(min-width: 1701px)and(max-width:1800px){
    margin-left:10px;
  }

  .search-input-wrapper {
    text-align: center;
    padding: 0px;
    z-index: 9;
    position: relative;

    .container{
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      transition: all 0.2s linear;
      @media(max-width:500px){
        padding: 0px;
        flex-wrap: nowrap;
        justify-content: space-between;
      }
      @media(max-width:767px)and(min-width:501px){
        padding: 0px;
        flex-wrap: nowrap;
        justify-content: space-between;
      }
      @media(max-width: 991px) and(min-width: 768px) {
        padding: 0px;
        margin:0px;
        flex-wrap: nowrap;
        justify-content: space-between;
        width: 100%;
        max-width: 100%;
      }
      @media(max-width: 1199px) and(min-width: 992px) {
        padding: 0px;
        margin:0px;
        flex-wrap: nowrap;
        justify-content: space-between;
        width: 100%;
        max-width: 100%;
      }
      @media(max-width: 1365px) and(min-width: 1200px) {
        padding: 0px;
        margin:0px;
        flex-wrap: nowrap;
        justify-content: space-between;
        width: 100%;
        max-width: 100%;
      }
      @media(max-width: 1700px) and (min-width: 1366px) {
        padding: 0px;
        margin:0px;
        flex-wrap: nowrap;
        justify-content: space-between;
        width: 100%;
        max-width: 100%;
      }
    }

    .search-wrapper {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      position: relative;
      margin-right: 19px;
      @media(max-width:500px){
        margin:0px;
        width: 100%;
      }
      @media(max-width:767px)and(min-width:501px){
        margin:0px;
        width: 100%;
      }
      @media(max-width: 991px) and(min-width: 768px) {
        margin:0px;
        width: 100%;
      }
      @media(max-width: 1199px) and(min-width: 992px) {
        margin:0px;
        width: 100%;
      }
      @media(max-width: 1365px) and(min-width: 1200px) {
        margin:0px;
        width: 100%;
      }
      @media(max-width: 1700px) and (min-width: 1366px) {
        margin:0px;
        width: 100%;
      }

      input {
        font-size: 18px;
        padding: 19px 28px;
        line-height: 1;
        border: none;
        width: 680px;
        transition: all 0.3s linear;
        border-radius: 60px;
        background-color: rgba(255,255,255,0.05);
        color:#FFF;
        outline: none !important;

        @media(max-width:500px){
          width:100%;
          padding: 10px 23px;
          margin:0px
        }
        @media(max-width:767px)and(min-width:501px){
          width:100%;
          padding: 10px 23px;
          margin:0px
        }
        @media(max-width: 991px) and(min-width: 768px) {
          width:100%;
          padding: 18px 23px;
          margin:0px
        }
        @media(max-width: 1199px) and(min-width: 992px) {
          padding: 18px 23px;
          margin:0px;
          width: 100%;
        }
        @media(max-width: 1365px) and(min-width: 1200px) {
          padding: 18px 23px;
          margin:0px;
          width: 100%;
        }
        @media(max-width: 1700px) and (min-width: 1366px) {
          padding: 18px 23px;
          margin:0px;
          width: 100%;
        }
        @media(min-width: 1701px)and(max-width:1800px){
          width: 545px;
        }


        &:focus{
          background-color: #FFF;
          color:#212121;

        }

        &::placeholder{
          font-size:18px;
          color:rgba(255,255,255,0.76);
        }
      }

      img{
        position: absolute;
        right: 20px;
        max-width: 27px;
        @media(max-width:500px){
          display:none;
        }
        @media(max-width:767px)and(min-width:501px){
          display:none;
        }
        @media(max-width: 991px) and(min-width: 768px) {
          display:none;
        }
      }
    }

    @media(min-width: 1440px)and(max-width:1700px){
      max-width: 70%;
      margin: 10px auto 0px;
    }


    .button-more {
      background-color: transparent;
      border: none;
      cursor: pointer;
      @media(max-width:500px){
        padding:0px;
        margin-left: 5px;
      }
      @media(max-width:767px)and(min-width:501px){
        padding:0px;
        margin-left: 5px;
      }
      @media(max-width: 991px) and(min-width: 768px) {
        padding:0px;
        margin-left: 5px;
      }

      img{
        max-width: 77px;
        @media(max-width:500px){
          max-width: 55px;
        }
        @media(max-width:767px)and(min-width:501px){
          max-width: 55px;
        }

      }
    }
  }

  .advanced-filters-wrapper {
    padding: 50px 0px 58px 0px;
    background-color: #FFF;
    position: absolute;
    left: 0;
    right: 0;
    border: 1px solid #eee;
    border-radius: 20px;


    z-index: 3;
    opacity: 0;
    display: none;
    // transition: opacity 0.3s linear;

    &.is_open {
      transform: translate(0px, 33px);
      opacity: 1;
      display: block;
    }

    .button-search, .button-more {
      font-size: 1em;
      margin-top: 20px;
      text-transform: uppercase;
      letter-spacing: 1.2px;
    }
  }
}
