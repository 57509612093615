:root{
  --select-height: 63px;
}
@media(max-width: 500px){
  :root{
    --select-height: 42px;
  }
}


#filter {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-column-gap: 15px;
  grid-row-gap: 15px;

  * > {
    letter-spacing: 1px;
  }

  @media(max-width: 500px) {
    grid-template-columns: 1fr 1fr;
  }
  @media(max-width: 991px) and(min-width: 768px) {
    grid-template-columns: 1fr 1fr 1fr;
  }
  @media(max-width: 1199px) and(min-width: 992px) {
    grid-template-columns: 1fr 1fr 1fr;
  }


  .button-wrapper{
    display: flex;
    flex-direction: column;
    justify-content: end;
    button{
      border-radius:63px;
      font-size: 18px;
      border:4px solid $successColor;
      display: block;
      width: 100%;
      text-align: center;
      padding:20px 10px;
      cursor: pointer;
      @media(max-width:500px){
        padding:12px 10px;
        background-image:none !important;
      }
      @media(max-width: 991px) and(min-width: 768px) {
        padding:12px 10px;
        background-image:none !important;
      }
      @media(max-width: 1199px) and(min-width: 992px) {
        padding:12px 10px;
        background-image:none !important;
      }


      &#filter_closeButton{
        background-color: #D8D8D8;
        color:#212121;
        border-color: #D8D8D8;
        &:hover{
          background-color: transparent;
          color:#212121;
        }
      }
      &#filter_submitButton{
        background-color: $successColor;
        color:#FFF;
        font-weight: bold;
        background-image: url("../../search-icon.svg");
        background-repeat: no-repeat;
        background-position: 66% 46%;
        background-size: 28px;
        transition: all 0.2s linear;

        &:hover{
          background-position: 66% 32%;
          // background-color: transparent;
          // color:$successColor;
        }
      }

    }
  }

}

.advanced-filters-wrapper, .filter-bar {

  .button-search {
    font-size: 0.9em !important;
  }

  input {
    letter-spacing: 1px;

    &:focus {
      outline: none;
    }

    &[type="text"] {
      width: 100%;
      border: 1px solid #e5e5e5;
      border-radius: 10px;
      padding: 23px 20px;
      margin-bottom: 0px;
      background-color: #FFF;
      @media(max-width:500px){
        padding:14px 10px;

      }

      &::placeholder {
        text-transform: uppercase;
        letter-spacing: 1.2px;
        font-size: 11px;
        color: #444;
      }

      &:focus {
        border: 1px solid $accent1;
        background-color: #FFF;
      }
    }
  }

  label {
    letter-spacing: 1px;
    display: block;
    margin-bottom: 8px;
    font-weight: bold;

  }

  .filter_row {
    overflow: hidden;
  }

  .select2-container {
    border-radius: 10px;
    display: block;
    &.select2-container--open {
      background-color: #FFF !important;

      .select2-selection {
        border-color: $accent1;
        background: none;
      }

      .select2-selection--multiple {
        .select2-selection__clear {
          background: #FFF;
          border: 1px solid $accent1;
          border-left: none;
          border-bottom: none;
        }
      }
    }

    * > {
      letter-spacing: 1px;
    }

    .select2-selection--single {
      background-image: none;
      border: 1px solid #e5e5e5;
      background-color: #FFF;
      border-radius: 10px;

      min-height: var(--select-height);
      max-height: var(--select-height);

      .select2-selection__clear{
        height:  var(--select-height) !important;
      }

      .select2-selection__arrow {
        background-image: none !important;
        background-color: #cacaca;

        b {
          border-color: $accent1 transparent transparent transparent;
        }
      }
    }

    //
    .select2-selection--multiple {
      padding: 0px 5px;
      border: 1px solid #e5e5e5;
      background-color: #FFF;
      border-radius: 10px;

      min-height: var(--select-height);
      max-height: var(--select-height);

      overflow: hidden;

      display: flex;
      align-items: center;


      .select2-search {
        background-color: transparent;
        position: relative;
        display: block;
        width: 100%;

        input {
          margin: 5px 0px !important;
          background: transparent !important;
          padding: 0px 5px;
          outline: none !important;

          width: 100% !important;

          text-transform: uppercase;
          letter-spacing: 1.2px;
          font-size: 11px;
          color: #444;

          &:focus {
            border: 1px solid $accent1;
            background-color: #FFF;
            outline: none !important;
          }
        }
      }

      .select2-selection__clear {
        display: block;
        position: absolute;
        right: 0px;
        font-size: 20px;
        top: 0;
        bottom: 0;
        z-index: 1;
        color: $accent1;

        background: #f5f0f0;
        border: 1px solid #cacaca;
        border-left: none;
        width: 29px;
        cursor: pointer;
      }

      .select2-selection__rendered {
        list-style: none;
        padding: 0;
        display: flex;
        margin: 0;
        position: relative;
        white-space: nowrap;


        .select2-search__field {
          background-color: transparent;
          outline: none !important;
          border: 1px solid transparent;

          &:hover, &:focus {
            outline: none !important;
          }
        }



        .select2-selection__choice {
          background-color: transparent;
          border: none;
          text-transform: uppercase;
          font-size: 11px;
          letter-spacing: 1.2px;
          color: #000;
          position: relative;

          margin: 0px;
          padding: 0px;

          &:not(:last-child) {
            &:after {
              content: ',';
              position: relative;
              left: -5px;
            }
          }

          .select2-selection__choice__remove {
            display: none;
            color: #FFF;
            margin-right: 6px;
          }
        }
      }
    }


    .select2-selection--single {
      height: 37px;

      .select2-selection__placeholder {
        text-transform: uppercase;
        letter-spacing: 1.2px;
        font-size: 11px;
        color: #444;
      }

      .select2-selection__clear {
        font-size: 20px;
        font-weight: 300;
        color: $accent1;
        top: 0px;
        position: relative;
      }

      .select2-selection__arrow {
        background-color: transparent;
        height: var(--select-height);
        border: none;
      }

      .select2-selection__rendered {
        line-height: var(--select-height);
        padding-left: 20px;
      }
    }
  }
}

.select2-search__field {
  border: 1px solid transparent !important;
  outline: none;
  background-color: #f6f6f6 !important;
  padding: 10px !important;

  &:hover &:focus {
    outline: none !important;
  }


}

.select2-search--dropdown{
  padding: 0px !important;
}

.select2-dropdown {
  border-color: $accent1 !important;
  border-radius: 10px;


  .select2-search__field {
    border-color: #d0d0d0;
    border-radius: 8px;
  }

  .select2-results__option--selected {
    background-color: $successColor;
    color: #FFF;
    //border-bottom: 1px solid #06`775c;
    position: relative;

    &:after {
      content: '';
      position: absolute;
      top: 0px;
      bottom: 0px;
      right: 6px;

      width: 17px;
      background-image: url(../../correct.svg);
      background-position: center;
      background-size: 11px;
      background-repeat: no-repeat;

    }
  }

  .select2-results {
    .select2-results__option--highlighted {
      background-color:  $successColor;
      color: #FFF;
    }

    .select2-results__option {
      //padding: 11px 6px;
      padding: 14px 20px;
      font-size: .9em;
    }


    .select2-results__options {

    }
  }
}

