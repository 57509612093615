h1 {
  margin-top: 0px;
  font-size: 48px;
}

.mt-100 {
  margin-top: 100px;
}

.mb-100 {
  margin-bottom: 100px;
}


.section-heading {
  display: flex;
  justify-items: center;
  justify-content: space-between;
  margin-bottom: 37px;
  @media(max-width: 500px) {
    flex-direction: column;
    .buttons-wrapper {
      text-align: center;
      max-width: 300px;
    }
  }

  .title--wrapper {
    @media(max-width: 991px) and(min-width: 768px) {
      flex: 1;
    }

    h2 {
      font-size: 48px;
      font-weight: bold;
      margin-bottom: 10px;
      margin-top: 0px;
    }

    p {
      font-size: 18px;
      color: #828282;
    }

  }

  .buttons-wrapper {
    display: flex;
    flex-direction: column;
    @media(max-width: 991px) and(min-width: 768px) {
      max-width: 40%;
    }
  }

  @media(max-width: 991px) and(min-width: 768px) {
    flex-direction: column;
  }
}